import { getDayOfWeek, getLocalTimeZone, parseDate, today } from '@internationalized/date'

export function useTags() {
  const { t } = useI18n()

  const getAvailableTag = (dateString: string) => {
    const now = today(getLocalTimeZone())
    const [datePart] = dateString.includes('T') ? dateString.split('T') : [dateString]
    const date = parseDate(datePart)
    const baseText = t('Skladem')
    const compare = date.compare(now)
    if (compare === 1) {
      return `${baseText} ${t('zítra')}`
    } else {
      const dayOfWeek = getDayOfWeek(date, 'cs')
      if (dayOfWeek + compare < 13) {
        let dayName
        let preposition
        switch (dayOfWeek) {
          case 1:
            dayName = t('úterý')
            preposition = t('v')
            break
          case 2:
            dayName = t('středu')
            preposition = t('ve')
            break
          case 3:
            dayName = t('čtvrtek')
            preposition = t('ve')
            break
          case 4:
            dayName = t('pátek')
            preposition = t('v')
            break
          case 5:
            dayName = t('sobotu')
            preposition = t('v')
            break
          case 6:
            dayName = t('neděli')
            preposition = t('v')
            break
          default:
            dayName = t('pondělí')
            preposition = t('v')
            break
        }
        return `${baseText} ${preposition} ${dayName}`
      }
    }

    return `${baseText} ${toLocaleDate(datePart)}`
  }

  return {
    getAvailableTag,
  }
}
